body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ALL */
* {
  margin: 0 auto;
  text-align: center;
  font-family: 'Raleway', serif;
}

body, html {
  height: 100vh;
}
body {
  font-size: 1.5em;
}

/* MAIN */

main {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 68vh;
}

/* HOME */
.home-container {
  width: 60%;
}

.sideways {
  display: flex;
  flex-direction: row;
  margin: 1em 0;
}
.sale-button {
  margin: 1em;
  border-radius: .3em;
  background-color: rgb(8, 8, 119);
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
}
.sale-button a {
  color: white;
}

.sale-button a:visited {
  text-decoration: none;
}
.sale-button a:hover {
  text-decoration: none;
  /* transition: transform */
}
.sale-button:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
#homeCarousel {
  max-height: none;
}

.container {
  margin: 1em auto 0 auto;
}
.title {
color: #BF554C;
font-weight: 700;
letter-spacing: .08em;
font-size: 1.5em ;
}
.subtitle {
  color: #9DCAE5;
  font-weight: 300i;
  letter-spacing: .08em;
}

.cmk_full img {
  width: auto;
  max-height: 10em;
}

.welcome {
  color: #9DCAE5;
  font-weight: 300i;
  letter-spacing: .08em;
  text-align: left;
}
.description {
  font-size: .8em;
  margin: 0 0 0 1em;
}

.specialties {
  font-size: .9em;
  margin-bottom: 0;
}

.extra-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.extra-links a {
  text-decoration: none;
}
.extra-images img{
  height: 12em;
  width: auto;
  margin: 1.5em 1.5em 0 1.5em;

}


/* ABOUT */

.about {
  width: 60%
}

.about * {
  margin: .5em;
}


.about-background {

  background-image: url(/static/media/waves_cannon_07.34509bfe.jpg);
}

.about li {
  list-style: none;
}

/* NAV */

.navbar {
  font-size: 1.0em;
  padding: 0;

}

.dropdown:hover>.dropdown-menu {
  display: block;
}

.nav-item:not(:first-child) {
  margin: 0 1em;
}

.nav-item a:hover {
  background-color: #9DCAE5;
}

.slider {
  max-height: 2.1em;
}

.nav-link p {
  margin-bottom: 0;
}

.nav-item hr {
  margin: 0;
  border-top: 4px solid #BF554C;
  
}


@-webkit-keyframes slide-in {
  /* from { opacity: 1; }
  to   { opacity: 0; } */
  from { width: 0%; }
  to   { width: 100%; }
}


@keyframes slide-in {
  /* from { opacity: 1; }
  to   { opacity: 0; } */
  from { width: 0%; }
  to   { width: 100%; }
}

.slide-in {

  -webkit-animation: slide-in 0.2s ease-out; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: slide-in 0.2s ease-out;

  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;

}


@-webkit-keyframes fade-out {
  /* from { opacity: 1; }
  to   { opacity: 0; } */
  from { font-size: 1em; }
  to   { font-size: 2em; }
}


@keyframes fade-out {
  /* from { opacity: 1; }
  to   { opacity: 0; } */
  from { font-size: 1em; }
  to   { font-size: 2em; }
}
.fade-out {

  -webkit-animation: fade-out 2s ease-in; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: fade-out 2s ease-in;

  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.fade-in {
  opacity: 0;

  -webkit-animation: fade-in 2s ease-in; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: fade-in 2s ease-in;

  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
@-webkit-keyframes fade-in {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@keyframes fade-in {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* SECTIONS */

.section-image {
  height: 8em;
  background-size: cover;
}

/* SECTION IMAGES */

.psychotherapy-image {
  background-position: 50% 45%;
  background-image: url(/static/media/psychotherapy-banner.6d92c784.jpg);
}

.medication-image {
  background-position: 50%;
  background-image: url(/static/media/1fd32a5a7af89ce657a1129e583d47be.1fd32a5a.jpg);
}

.genetic-image {
  background-position: 50% 45%;
  background-image: url(/static/media/genetic-testing-banner.22821845.jpg);
}


.anxiety-image {
  background-position: 50%;
  background-image: url("/static/media/Hugo bend (front view).a9f30644.JPG")
}

.depression-image {
  background-position: 50% 30%;
  background-image: url(/static/media/depression-be.b485fee8.jpg);
}

.sleep-image {
  background-position: 50% 40%;
  background-image: url(/static/media/Sleeping.d283cd46.jpeg);
}

.PTSD-image {
  background-position: 50% 70%;
  background-image: url(/static/media/cc32e202-8685-48b5-bf52-c45da58bc4f6-large16x9_MO1_1838Edit.f331ce1c.jpg);
}


.geriatric-image {
  background-position: 50% 40%;
  background-image: url(/static/media/Senior-Travel-Problems.de141340.jpg);
}
/* SIDE NAVIGATION */
.dropdown-content {
  width: 60%;
  display: flex;
  flex-direction: row;
  font-size: .8em;
}

.dropdown-content a {
  font-size: 1.5em;
  color: #696969;
  text-decoration: none;
}

.dropdown-content a:hover {
  color: black;
}
.dropdown-content ul {
  list-style: none;
  
  margin: .5em;
}


  


.dropdown-content h1 {
  margin: .5em;
}

.side-nav-container {
  min-width: 40%;
}

/* POLICIES */

.policies-container {
  width: 70%;
}

.policy-header {
  font-size: 2em;
}


.policies-container span {
  font-weight: 700;
} 

.policies-container h1 {
  margin: 1em 0;
}






/* MAP */

.m-container {
  position: relative;
  min-height: 100vh;
  min-width: 70%;
}

.m-container h2 {
  max-width: 60%;
}

.map-container {
  max-height: 70vh !important;
  
}

.footer-container {
  height: 30%;
}
.prefooter {
  background-color: #7F7F7D;
  width: 100%;
  color: white;
  margin: 0px;
  font-size: 1em;
  padding: 2em;
}
.prefooter p {
  font-size: .5em;
}
.footer {
  background-color: #403F3F;
  width: 100%;
  color: white;
  margin: 0px;
  font-size: .6em;
  padding: 2em;
}

/* RESOURCES */
.document-grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 60%;
}

.generated-document {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 10px;
    height: 8em;
    width: 8em;
    border: solid .2em #444140;
    margin: .5em;      
}

.tired {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
}

.generated-document img {
  height: 3em;
  width: auto;
}

.generated-document a {
  text-decoration: none;
}
/* COVID */
.covid-container {
  width: 75%;
}

.covid-content {
  padding: .8em;  
}
.consent-text {
  padding: 2em; 
}
.nyt {
  font-size: .5em;
}

.bold-span {
  font-weight: bold;
}
/* MOBILE SMALL */

@media (max-width: 321px) {
  /* HOME */
  .navbar-brand {
    font-size: .7em;
  }
  .cmk_full img {
    margin: 1em 0;
    max-height: 7em;
  }
  /* ABOUT */
  .about h1 {
    font-size: 2rem;
  }
}

/* .huge {
  font-size: 100px;
} */
/* REMOVE IPHONE ANIMATION */

/* @supports (-webkit-overflow-scrolling: touch) { */
  /* .nav-link {
    pointer-events: none;
  } */
/*   
} */

/* MOBILE LARGE */

@media (max-width: 481px) {
  /* HOME */
  .home-container {
    width: 90vw;
  }

  .cmk_full img {
    margin: 1em 0;
    max-height: 8em;
  }

  .sideways {
    display: inline;
    display: initial;
  }
  .extra-links {
    display: inline;
    display: initial;
    /* flex-direction: ;
    justify-content: space-between; */
  }
  .extra-images img { 
    height: auto;
    width: 90vw;
    margin: 1.5em 0;
  }
  figure {
    margin: 0;
  }
  .fig-bottom {
    margin-bottom: 1.5em;
  }
  /* ABOUT */
  .about {
    width: auto;
    width: initial;
  }  
  .about ul {
    padding: 0;
  }

  /* SECTION */
  .side-nav-container {
    display: none;
  }
  .dropdown-content {
    width: auto;
    width: initial;
  }

  /* POLICIES */
  .policies-container {
    width: auto;
    width: initial;
  }
  /* CONTACT */
  .m-container {
    min-height: 0;
    min-height: initial;
  }
}

/* LAPTOP */

@media (max-width: 1441px) {
  .navbar {
    font-size: .8em;
  }
}
